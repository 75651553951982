import {
  ButtonStratto,
  FiltersStrattoAvailability,
  // UtilitiesStratto,
} from "../ComponentsUtilities";
import { makeStyles } from "@mui/styles";
import PropertyFloorplans from "./PropertyFloorplans";
import { Box, Grid, Stack } from "@mui/material";
import { useLayoutEffect, useMemo } from "react";
import { useCurrentStrattoAvailabilityModal, useStrattoFiltersValues, useStrattoUnits } from "../../hooks/hooksStratto";
import { useAppDispatch } from "../../hooks";
import { cleanStrattoFilters, setCurrentStrattoAvailabilityModal, setStrattoCurrentApartment } from "../../store/todoStratto-actions";
import AvailabilityModal from "../AvailabilityScreen/AvailabilityModal";

// const arrNumber = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2,
//   3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2, 3, 4,
//   5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
// ];

export default function FloorPlansScreen() {
  const dispatch = useAppDispatch()
  const classes = useStyles();
  const openModalFloorPlan = useCurrentStrattoAvailabilityModal()
  console.log('openModalFloorPlan',openModalFloorPlan)
  const strattoUnits = useStrattoUnits()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [
    bedroomFilter,
    bathroomFilter,
    floorplanFilter,
    priceFilter,
    areaFilter,
    levelFilter,
    statusFilter
  ] = useStrattoFiltersValues();

  const filterObject = useMemo(()=>{
    return {
      bedrooms: bedroomFilter,
      bathrooms: bathroomFilter,
      'floor_plan_code': floorplanFilter?.toUpperCase() || null,
      status: statusFilter,
      floor: levelFilter
    }
  },[bathroomFilter, bedroomFilter, floorplanFilter, levelFilter, statusFilter])

  const filterUnits = useMemo(() => {
    return bathroomFilter == null &&
      bedroomFilter == null &&
      floorplanFilter == null &&
      priceFilter == null &&
      areaFilter == null &&
      levelFilter == null
      ? strattoUnits
      : strattoUnits.filter((unit: any) => {
          return Object.entries(filterObject).every(([key, value])=>{
            if(value === null || value===undefined) return true;
            return unit[key] === value
          });
          // unit.bathrooms === bathroomFilter &&
          //   unit.bedrooms === bedroomFilter &&
          //   unit.floor_plan_code?.toLowerCase() === floorplanFilter &&
          //   unit.floor === levelFilter;
        });
  }, [areaFilter, bathroomFilter, bedroomFilter, filterObject, floorplanFilter, levelFilter, priceFilter, strattoUnits]);


  useLayoutEffect(()=>{
    dispatch(cleanStrattoFilters())
  },[dispatch])


  return (
    <>
      {!openModalFloorPlan && (
        <>
          <div
            style={{
              backgroundColor: "#1a1919",
              width: "100%",
              height: "100%",
            }}
          >
            {/* <div className={classes.utilities}>
              <UtilitiesStratto />
            </div> */}
            <div
              style={{
                width: "100%",
              }}
            >
              <FiltersStrattoAvailability />
            </div>
            <div className={classes.containerFloorPlans}>
              <div className={classes.bodyFloorPlans}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    {filterUnits?.filter((item: any) => item.status !== 1).sort((a: any, b:any)=> Number(a?.name) - Number(b?.name)).map((e: any, index: number) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                        <div onClick={() => {
                          dispatch(setStrattoCurrentApartment(e))
                          dispatch(setCurrentStrattoAvailabilityModal(true))
                          
                        }}>
                          <PropertyFloorplans
                            price={e.price}
                            key={e.number}
                            name={e.name}
                            bedroom={e.bedrooms}
                            bathroom={e.bathrooms}
                            areaTotal={e.area}
                            images={e.images}
                          />
                        </div>
                      </Grid>
                    ))}
                    {/* {views[2]?.components[0]?.apartments[2]?.units?.map((e: any) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div onClick={() => setOpenModalFloorPlan(true)}>
                          <PropertyFloorplans price={e.price} key={e.number} name={e.name} bedroom={e.bedrooms} bathroom ={e.bathrooms} areaTotal={e.area} />
                        </div>
                      </Grid>
                    ))} */}
                  </Grid>
                </Box>
              </div>
            </div>
            <Stack
              sx={{
                position: "absolute",
                bottom: "16px",
                width: "100%",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <ButtonStratto name="Amenidades" path="amenities-broll" />
            </Stack>
          </div>
        </>
      )}
      {openModalFloorPlan && (
        <AvailabilityModal open={openModalFloorPlan} onClose={()=>dispatch(setCurrentStrattoAvailabilityModal(false))}/>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  containerFloorPlans: {
    display: "block",
    width: "100%",
    height: "90%",
    overflow: "hidden",
    "@media (max-width: 960px)": {
      height: "100%",
    },
  },
  bodyFloorPlans: {
    margin: "80px 50px",
    paddingRight: "5px",
    overflowY: "scroll",
    height: "86%",
    "::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "@media (max-width: 640px)": {
      margin: "20px",
    },
  },
  utilities: {
    "@media (max-width: 640px)": {
      position: "absolute",
      bottom: "50px",
      right: "12px",
    },
  },
  deleteBtn: {
    cursor: "pointer",
    position: "absolute",
    top: "20px",
    right: "10px",
    "&:hover": {
      opacity: "0.8",
    },
    "@media (max-width: 640px)": {
      width: "30px",
      position: "absolute",
      top: "5px",
      right: "10px",
    },
  },
}));
