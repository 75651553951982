import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { PlaceInfo } from "./PlaceInfo";
import CocheSvg from "../../assets/icons/coche.svg";
import CaminarSvg from "../../assets/icons/caminar.svg";

const libraries: any = ["places"];
const mapContainerStyle = {
  height: "90vh",
  width: "70%",
};
// 地図の大きさを指定します。

const options = {
  //  styles: mapStyles,
  disableDefaultUI: true,
  // デフォルトUI（衛星写真オプションなど）をキャンセルします。
  zoomControl: true,
};

// const InfoMobileView = () => {
//   const mobileMini = useMediaQuery("(max-width:320px)");
//   const mobile = useMediaQuery("(max-width:601px)");
//   const tabletMini = useMediaQuery("(max-width:820px)");
//   const setWidthText = () => {
//     if (mobileMini) {
//       return "235px";
//     }
//     if (mobile) {
//       return "270px";
//     }
//     return "390px";
//   };
//   return (
//     <Box
//       width={"100%"}
//       height={"100%"}
//       display={"flex"}
//       alignItems={"center"}
//       justifyContent={"center"}
//       bgcolor={"#FFFFFF"}>
//       <Box
//         width={"100%"}
//         textAlign={"left"}
//         padding={tabletMini ? "0 80px" : "0 40px"}>
//         <div>
//           <Typography
//             fontWeight={300}
//             fontSize={"16px"}
//             color={"rgba(0, 0, 0, 1)"}>
//             AMENIDADES
//           </Typography>
//         </div>
//         <Box marginTop={"20px"}>
//           <Alberca />
//         </Box>
//         <Box marginTop={"20px"}>
//           <Typography
//             fontWeight={900}
//             fontSize={"28px"}
//             color={"rgba(0, 0, 0, 1)"}>
//             Alberca
//           </Typography>
//         </Box>
//         <Box
//           marginTop={"15px"}
//           display={"flex"}
//           alignItems={"center"}
//           justifyContent={"flex-start"}>
//           <Typography
//             fontSize={"20px"}
//             fontWeight={300}
//             color={"rgba(0, 0, 0, 1)"}>
//             Nivel:
//           </Typography>
//           <Typography
//             marginLeft={"6px"}
//             fontSize={"20px"}
//             fontWeight={700}
//             color={"rgba(0, 0, 0, 1)"}>
//             40
//           </Typography>
//         </Box>
//         <Box marginTop={"20px"} width={setWidthText()}>
//           <Typography
//             fontSize={"14px"}
//             fontWeight={400}
//             color={"rgba(0, 0, 0, 1)"}>
//             Admira la ciudad desde las alturas disfrutando un día soleado al
//             aire libre compartiendo una deliciosa comida y buenos momentos.
//           </Typography>
//         </Box>
//         <Box marginTop={"20px"}>
//           <ul
//             style={{
//               fontSize: "14px",
//               fontWeight: "400",
//               color: "rgba(0, 0, 0, 1)",
//             }}>
//             <li style={{ marginTop: "20px" }}>Asadores y mesas</li>
//             <li style={{ marginTop: "20px" }}>Wellness area</li>
//             <li style={{ marginTop: "20px" }}>
//               Lounge area para tus reuniones
//             </li>
//             <li style={{ marginTop: "20px" }}>Terraza de 300 m2</li>
//           </ul>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

function MapScreenModal({ open, onClose }: any) {
  //   const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  //   const desktopSmall = useMediaQuery("(max-width:1500px)");
  const desktopNormal = useMediaQuery("(max-width:1870px)");
  //   const tablet = useMediaQuery("(max-width:1180px)");
  //   const tabletSmall = useMediaQuery("(max-width:1024px)");
  const tabletMini = useMediaQuery("(max-width:820px)");
  const mobile = useMediaQuery("(max-width:601px)");
  //   const mobileMini = useMediaQuery("(max-width:320px)");
  const { isLoaded, loadError } = useLoadScript({
    //googleMapsApiKey: process.env.REACT_APP_googleMapsApiKey,
    googleMapsApiKey: "",
    // ここにAPIキーを入力します。今回は.envに保存しています。''
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);
  //API読み込み後に再レンダーを引き起こさないため、useStateを使わず、useRefとuseCallbackを使っています。

  if (loadError || !isLoaded) return null;
  const closeButtonRight = () => {
    if (tabletMini) {
      return "1%";
    }
    if (desktopNormal) {
      return "20px";
    }
    return "20px";
  };
  //   const marginSpace = () => {
  //     if (tabletMini) {
  //       return "0px";
  //     }
  //     if (desktopNormal) {
  //       return "50px";
  //     }
  //     return "55px";
  //   };
  const setFontSizeIcon = () => {
    if (mobile) {
      return "19px";
    }
    if (tabletMini) {
      return "40px";
    }
    return;
  };
  return (
    <div style={{ zIndex: "9999" }}>
      <Modal open={open} onClose={onClose}>
        <div>
          <Box width={"100%"} height={"100%"}>
            <Box
              bgcolor={tabletMini ? "" : "rgba(255, 255, 255, 1)"}
              position={"absolute"}
              top={tabletMini ? "unset" : "50%"}
              left={tabletMini ? "unset" : "49%"}
              sx={{
                transform: tabletMini ? "unset" : "translate(-50%, -50%)",
                outline: "none",
              }}
              borderRadius={"15px"}
              padding={tabletMini ? "0px" : "25px"}
              width={tabletMini ? "100%" : "90vw"}
              height={tabletMini ? "100%" : "92vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Box pl={4}>
                <Typography
                  fontSize={"36px"}
                  fontWeight={400}
                  fontFamily={"sans-serif"}>
                  La Zona
                </Typography>
                <Box>
                  <Typography
                    fontSize={"20px"}
                    fontWeight={700}
                    py={"10px"}
                    fontFamily={"sans-serif"}>
                    Tiendas y Entretenimiento
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CaminarSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(5 min)</Typography>
                    <Typography>Walmart Express Plaza Lilas</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CaminarSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(10 min)</Typography>
                    <Typography>Paseo Arcos Bosques</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(11 min)</Typography>
                    <Typography>Centro Santa Fe</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    fontSize={"20px"}
                    fontWeight={700}
                    py={"10px"}
                    fontFamily={"sans-serif"}>
                    Educación y Salud
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(12 min)</Typography>
                    <Typography>Centro Medico ABC</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(14 min)</Typography>
                    <Typography>Universidad Iberoamericana</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(15 min)</Typography>
                    <Typography>Paseo Arcos Bosques</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(20 min)</Typography>
                    <Typography>Universidad Anáhuac</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    fontSize={"20px"}
                    fontWeight={700}
                    py={"10px"}
                    fontFamily={"sans-serif"}>
                    Parques y Exterior
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(16 min)</Typography>
                    <Typography>La Mexicana</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(20 min)</Typography>
                    <Typography>Bosque de Chapultepec</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={1} pl={2}>
                    <img src={CocheSvg} loading="lazy" alt="" />
                    <Typography fontStyle={"italic"}>(25 min)</Typography>
                    <Typography>Desierto de los Leones</Typography>
                  </Box>
                </Box>
              </Box>
              <GoogleMap
                id="map"
                mapContainerStyle={mapContainerStyle}
                zoom={14} // デフォルトズーム倍率を指定します。
                center={{
                  lat: 19.3847837,
                  lng: -99.2494799,
                }} // 札幌周辺にデフォルトのセンターを指定しました。
                options={options}
                onLoad={onMapLoad}
                >
                <PlaceInfo />
              </GoogleMap>
            </Box>
            <div onClick={onClose}>
              <Box
                position={"absolute"}
                width={"48px"}
                height={"48px"}
                bgcolor={tabletMini ? "" : "rgba(255, 255, 255, 1)"}
                top={tabletMini ? "1%" : "15px"}
                right={closeButtonRight()}
                sx={{ cursor: "pointer" }}
                borderRadius={"50%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                zIndex={10000}>
                <CloseIcon sx={{ fontSize: setFontSizeIcon() }} />
              </Box>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default MapScreenModal;
