import { Stack } from "@mui/material";

import { useAppDispatch, useSvgType } from "../../hooks";
import { setSVGType } from "../../store/todo-actions";
import { setStrattoSvgType } from "../../store/todoStratto-actions";
import { useStrattoSvgType } from "../../hooks/hooksStratto";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  isStratto?: boolean;
};

export const ThreeDirectionChangeIcon = ({ color = "#fff" }: any) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2906_1444)">
      <path
        d="M4 4.625V3.375C4 3.16875 4.16875 3 4.375 3H5.625C5.83125 3 6 3.16875 6 3.375V4.625C6 4.83125 5.83125 5 5.625 5H4.375C4.16875 5 4 4.83125 4 4.625ZM8.375 5H9.625C9.83125 5 10 4.83125 10 4.625V3.375C10 3.16875 9.83125 3 9.625 3H8.375C8.16875 3 8 3.16875 8 3.375V4.625C8 4.83125 8.16875 5 8.375 5ZM4.375 8H5.625C5.83125 8 6 7.83125 6 7.625V6.375C6 6.16875 5.83125 6 5.625 6H4.375C4.16875 6 4 6.16875 4 6.375V7.625C4 7.83125 4.16875 8 4.375 8ZM8.375 8H9.625C9.83125 8 10 7.83125 10 7.625V6.375C10 6.16875 9.83125 6 9.625 6H8.375C8.16875 6 8 6.16875 8 6.375V7.625C8 7.83125 8.16875 8 8.375 8ZM6 10.625V9.375C6 9.16875 5.83125 9 5.625 9H4.375C4.16875 9 4 9.16875 4 9.375V10.625C4 10.8312 4.16875 11 4.375 11H5.625C5.83125 11 6 10.8312 6 10.625ZM8.375 11H9.625C9.83125 11 10 10.8312 10 10.625V9.375C10 9.16875 9.83125 9 9.625 9H8.375C8.16875 9 8 9.16875 8 9.375V10.625C8 10.8312 8.16875 11 8.375 11ZM14 15.375V16H0V15.375C0 15.1688 0.16875 15 0.375 15H0.984375V0.75C0.984375 0.334375 1.31875 0 1.73438 0H12.2656C12.6813 0 13.0156 0.334375 13.0156 0.75V15H13.625C13.8312 15 14 15.1688 14 15.375ZM2 14.9688H6V12.375C6 12.1688 6.16875 12 6.375 12H7.625C7.83125 12 8 12.1688 8 12.375V14.9688H12V1L2.01562 0.96875L2 14.9688Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2906_1444">
        <rect width="14" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TwoDirectionChangeIcon = ({ color = "#fff" }: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2906_1446)">
      <path
        d="M10.6685 0.533325H15.4685V15.4667H0.535156V0.533325H4.80182L8.00182 2.66666M6.93516 15.4667V7.99999M4.26849 7.99999H9.60182M12.8018 7.99999H15.4685"
        stroke={color}
        stroke-width="1.06667"
      />
    </g>
    <defs>
      <clipPath id="clip0_2906_1446">
        <rect width="16" height="16" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export const FloorPlanChangeIcon = ({ color = "#fff" }: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 5H6.6V6.6H5V5ZM9 5C8.78783 5 8.58434 5.08429 8.43431 5.23431C8.28429 5.38434 8.2 5.58783 8.2 5.8C8.2 6.01217 8.28429 6.21566 8.43431 6.36569C8.58434 6.51571 8.78783 6.6 9 6.6H13.8C14.0122 6.6 14.2157 6.51571 14.3657 6.36569C14.5157 6.21566 14.6 6.01217 14.6 5.8C14.6 5.58783 14.5157 5.38434 14.3657 5.23431C14.2157 5.08429 14.0122 5 13.8 5H9ZM6.6 8.2H5V9.8H6.6V8.2ZM8.2 9C8.2 8.78783 8.28429 8.58434 8.43431 8.43431C8.58434 8.28429 8.78783 8.2 9 8.2H13.8C14.0122 8.2 14.2157 8.28429 14.3657 8.43431C14.5157 8.58434 14.6 8.78783 14.6 9C14.6 9.21217 14.5157 9.41566 14.3657 9.56568C14.2157 9.71571 14.0122 9.8 13.8 9.8H9C8.78783 9.8 8.58434 9.71571 8.43431 9.56568C8.28429 9.41566 8.2 9.21217 8.2 9ZM6.6 11.4H5V13H6.6V11.4ZM8.2 12.2C8.2 11.9878 8.28429 11.7843 8.43431 11.6343C8.58434 11.4843 8.78783 11.4 9 11.4H13.8C14.0122 11.4 14.2157 11.4843 14.3657 11.6343C14.5157 11.7843 14.6 11.9878 14.6 12.2C14.6 12.4122 14.5157 12.6157 14.3657 12.7657C14.2157 12.9157 14.0122 13 13.8 13H9C8.78783 13 8.58434 12.9157 8.43431 12.7657C8.28429 12.6157 8.2 12.4122 8.2 12.2Z"
      fill={color}
    />
    <rect x="1.5" y="1.5" width="17" height="15" rx="1.5" stroke={color} />
  </svg>
);

export const BottomFilters = ({ isStratto = false }: Props) => {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const isAtFloorplan = pathname.includes('floorplans')
  const dispatch = useAppDispatch();
  const svgType = useSvgType();
  const strattoSvgType = useStrattoSvgType();
  const onClick2D = () => {
    if (isStratto) {
      dispatch(setStrattoSvgType("2d"))
      return isAtFloorplan && navigate("/availability")
    };
    dispatch(setSVGType("2d"));
  };
  const onClick3D = () => {
    if (isStratto) {
      dispatch(setStrattoSvgType("3d"))
      return isAtFloorplan && navigate("/availability")
    };
    dispatch(setSVGType("3d"));
  };

  console.log('isAtFloorplan',isAtFloorplan)

  return (
    <Stack direction="row" spacing={6}>
      <Stack
        direction="row"
        alignItems="end"
        sx={{
          marginRight: "10px",
        }}>
          <button
          style={{
            height: "32px",
            width: isStratto ? "48px" : svgType === "2d" ? "44px" : "58px",
            backgroundColor: isStratto
              ? isAtFloorplan
                ? "#F6F6F6"
                : "#979797"
              : svgType === "2d"
              ? "#B4FFEE"
              : "#F6F6F6",
            border: "1px solid #F6F6F6",
            fontWeight: isStratto
              ? isAtFloorplan
                ? "700"
                : "400"
              : svgType === "2d"
              ? "700"
              : "400",
            zIndex: isStratto
              ? isAtFloorplan
                ? "1"
                : "0"
              : svgType === "2d"
              ? "1"
              : "0",

            cursor: "pointer",
            borderRadius: "8px 0 0 8px",
          }}
          onClick={()=>navigate("/floorplans")}>
          <FloorPlanChangeIcon color={isAtFloorplan ? "#000" : '#fff'}/>
        </button>
        <button
          style={{
            height: "32px",
            width: isStratto ? "48px" : svgType === "2d" ? "44px" : "58px",
            backgroundColor: isStratto
              ? strattoSvgType === "2d" && !isAtFloorplan
                ? "#F6F6F6"
                : "#979797"
              : svgType === "2d"
              ? "#B4FFEE"
              : "#F6F6F6",
            border: "1px solid #F6F6F6",
            fontWeight: isStratto
              ? strattoSvgType === "2d" && !isAtFloorplan
                ? "700"
                : "400"
              : svgType === "2d"
              ? "700"
              : "400",
            zIndex: isStratto
              ? strattoSvgType === "2d"
                ? "1"
                : "0"
              : svgType === "2d"
              ? "1"
              : "0",

            cursor: "pointer",
          }}
          onClick={onClick2D}>
          <TwoDirectionChangeIcon color={strattoSvgType === "2d" && !isAtFloorplan ? "#000" : '#fff'}/>
        </button>
        <button
          style={{
            height: "32px",

            width: isStratto ? "48px" : svgType === "3d" ? "44px" : "58px",
            backgroundColor: isStratto
              ? strattoSvgType === "3d" && !isAtFloorplan
                ? "#F6F6F6"
                : "#979797"
              : svgType === "3d"
              ? "#B4FFEE"
              : "#F6F6F6",
            border: "1px solid #F6F6F6",

            fontWeight: isStratto
              ? strattoSvgType === "3d" && !isAtFloorplan
                ? "700"
                : "400"
              : svgType === "3d"
              ? "700"
              : "400",
            zIndex: isStratto
              ? strattoSvgType === "3d" && !isAtFloorplan
                ? "1"
                : "0"
              : svgType === "3d"
              ? "1"
              : "0",
            cursor: "pointer",
            borderRadius: "0 8px 8px 0",
          }}
          onClick={onClick3D}>
          <ThreeDirectionChangeIcon color={strattoSvgType === "3d" && !isAtFloorplan ? "#000" : '#fff'}/>
        </button>
      </Stack>
    </Stack>
  );
};
