import { Box, useMediaQuery } from "@mui/material";
import { ReactComponent as StrattoHomeLogo } from "../../../assets/icons/StrattoHomeLogo.svg";
import { useNavigate } from "react-router-dom";

export default function HomeIcon() {
  const mobile = useMediaQuery("(max-width:1365px)");

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "absolute",
        top:mobile ? '10px' : "30px",
        left:mobile ? '10px' : "30px",
      }}
    >
      <StrattoHomeLogo
        style={{
          width: mobile ? '40px' :"20%",
          height:mobile ? '40px' : "20%",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      />
    </Box>
  );
}
