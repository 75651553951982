import { Divider, Grid, Stack } from "@mui/material";

import { ReactComponent as StrattoHomeLogo } from "../../../assets/icons/StrattoHomeLogo.svg";
import { ReactComponent as FilterRemove } from "../../../assets/icons/FilterRemove.svg";
import { useMemo, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { FilterStrattoAvailability } from "./FilterStratto";
import { FilterOptions } from "../../../models/redux-models";
import { BottomFilters } from "../../BottomFilters";
import {
  useStrattoFilter,
  useStrattoFiltersValues,
} from "../../../hooks/hooksStratto";
import {
  cleanStrattoFilters,
  setStrattoBathrooms,
  // setStrattoBathrooms,
  setStrattoBedrooms,
  setStrattoFloorPlanType,
  setStrattoLevel,
} from "../../../store/todoStratto-actions";
import { HandleZoomStratto } from "../../HandleZoomStratto";

export const FiltersStrattoAvailability = ({handleZoomIn, handleZoomOut}: any) => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation()
  const [shouldClear, setShouldClear] = useState(false);


  const {
    bedrooms: bedroomsOptions,
    // bathrooms: bathroomOptions,
    floorplanTypes: floorplanTypesOptions,
    levels,
  } = useStrattoFilter();

  const [
    bedroomFilter,
    bathroomFilter,
    floorplanFilter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    priceFilter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    areaFilter,
    levelFilter,
  ] = useStrattoFiltersValues();

  const bedroomIndex = useMemo(
    () =>
     { 
      return bedroomsOptions.findIndex(
        (bedroom: { value: any }) => bedroom.value === `${bedroomFilter}-${bathroomFilter}`
      )},
    [bathroomFilter, bedroomFilter, bedroomsOptions]
  );


  const floorplanIndex = useMemo(
    () =>
      floorplanTypesOptions.findIndex(
        (floorplan: { value: string }) => floorplan.value === floorplanFilter
      ),
    [floorplanFilter, floorplanTypesOptions]
  );

  const levelIndex = useMemo(
    () =>
      levels.findIndex(
        (level: { value: number }) => level.value.toString() === levelFilter
      ),
    [levelFilter, levels]
  );

  // const statusIndex = useMemo(()=> status.findIndex(status => status.value === statusFilter),[status, statusFilter])

  const handleClick = () => {
    navigate("/")
  };

  const mobile = useMediaQuery("(max-width:1365px)");
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();


  return (
    <>
      {!mobile && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: 48,
            backgroundColor: "#000",
            padding: "0px 23px",
            position: "absolute",
            top: "0",
            zIndex: "1000",
          }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            alignContent="center">
            <Box
              sx={{
                color: "white",
                cursor: "pointer",
                marginRight: "10px",
                width: "32px",
                height: "32px",
              }}
              onClick={handleClick}>
              <StrattoHomeLogo
                style={{
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => navigate("/")}
              />
            </Box>
            <>
              <FilterStrattoAvailability
                key={"level"}
                text="Piso"
                options={levels}
                index={levelIndex}
                onChange={(event: { value: number }) =>
                  dispatch(setStrattoLevel(event.value))
                }
                shouldClear={shouldClear}
                resetShouldClear={() => {
                  setShouldClear(false);
                }}
              />
              <FilterStrattoAvailability
                key={"bedrooms"}
                text="Recs/Baño"
                options={bedroomsOptions?.concat([
                  { text: "Todas", value: null },
                ])}
                index={bedroomIndex}
                onChange={(event: { value: string }) => {
                  if (!event.value) {
                    dispatch(setStrattoBedrooms(null));
                    dispatch(setStrattoBathrooms(null));
                    return;
                  }
                  dispatch(
                    setStrattoBedrooms(Number(event.value.split("-")[0]))
                  );
                  dispatch(
                    setStrattoBathrooms(Number(event.value.split("-")[1]))
                  );
                }}
                shouldClear={shouldClear}
                resetShouldClear={() => {
                  setShouldClear(false);
                }}
              />
              <FilterStrattoAvailability
                key={"floorplan"}
                text="Planta tipo"
                options={(
                  floorplanTypesOptions as unknown as FilterOptions[]
                )?.concat([{ text: "Todas", value: null }])}
                index={floorplanIndex}
                onChange={(event: { value: string }) =>
                  dispatch(setStrattoFloorPlanType(event.value))
                }
                shouldClear={shouldClear}
                resetShouldClear={() => {
                  setShouldClear(false);
                }}
              />
              {/* {!pathname.includes("floorplans") &&<FilterStrattoAvailability
                key={"status"}
                text="Disponibilidad"
                options={status}
                index={statusIndex}
                onChange={(event: { value: number }) =>
                  {
                    console.log('event',event)
                    dispatch(setStrattoActiveStatus(event.value))
                  }
                }
                shouldClear={shouldClear}
                resetShouldClear={() => {
                  setShouldClear(false);
                }}
              />} */}
              {/* <FilterStrattoAvailability
                key={"price"}
                text="Price"
                options={floorplanTypesOptions}
                index={floorplanIndex}
                onChange={(event: { value: string }) =>
                  dispatch(setFloorPlanType(event.value))
                }
                shouldClear={shouldClear}
                resetShouldClear={() => {
                  setShouldClear(false);
                }}
              />

              <FilterStrattoAvailability
                key={"area"}
                text="Area"
                options={floorplanTypesOptions}
                index={floorplanIndex}
                onChange={(event: { value: string }) =>
                  dispatch(setStrattoAreas(event.value))
                }
                shouldClear={shouldClear}
                resetShouldClear={() => {
                  setShouldClear(false);
                }}
              /> */}
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{ backgroundColor: "#ffffff", height: 32 }}
              />
              <h3
                style={{
                  color: "white",
                  fontWeight: 700,
                  fontSize: 14,
                }}>
                Filtros
              </h3>
              <div style={{ cursor: "pointer" }}>
                <FilterRemove
                  onClick={() => {
                    setShouldClear(true);
                    dispatch(cleanStrattoFilters());
                  }}
                />
              </div>
              {!pathname.includes("floorplans") && (
                <HandleZoomStratto
                  handleZoomIn={handleZoomIn}
                  handleZoomOut={handleZoomOut}
                />
              )}
            </>
          </Stack>
          {/* {!pathname.includes("floorplans") && ( */}
            <div style={{ display: "flex" }}>
              <BottomFilters isStratto={true} />
            </div>
          {/* )} */}
        </Grid>
      )}
      {mobile && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: 56,
            backgroundColor: "#000000",
            cursor: "pointer",
            position: "unset",
            // position: currentLocation === 0 ? "absolute" : "unset",
          }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            alignContent="center">
            {!openMenu && (
              <Box
                sx={{
                  color: "white",
                  paddingLeft: "10px",
                  zIndex: "10",
                  width: "30px",
                }}
                onClick={handleClick}>
                <StrattoHomeLogo />
              </Box>
            )}
          </Stack>
          {
            <>
              <div
                onClick={() => {
                  setOpenMenu(true);
                }}>
                {!openMenu ? (
                  <Box pr={3}><MenuIcon /></Box>
                ) : (
                  <div
                    onClick={() => {
                      setShouldClear(true);
                      dispatch(cleanStrattoFilters());
                      setOpenMenu(true);
                    }}
                    style={{
                      marginTop: "12px",
                    }}>
                    <FilterRemove />
                  </div>
                )}
              </div>

              {openMenu && (
                <span
                  style={{
                    color: "white",
                    fontSize: "26px",
                    position: "relative",
                    right: "26px",
                    top: "4px",
                  }}
                  onClick={() => {
                    setOpenMenu(false);
                  }}>
                  <DeleteIcon />
                </span>
              )}

              {openMenu && (
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#000",
                    zIndex: "100",
                    width: "100%",
                    minHeight: "1200px",
                  }}>
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />

                  <FilterStrattoAvailability
                    key={"level"}
                    text="Piso"
                    options={levels}
                    index={levelIndex}
                    onChange={(event: { value: number }) =>
                      dispatch(setStrattoLevel(event.value))
                    }
                    shouldClear={shouldClear}
                    resetShouldClear={() => {
                      setShouldClear(false);
                    }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />
                  <FilterStrattoAvailability
                    key={"bedrooms"}
                    text="Recs/Baño"
                    options={bedroomsOptions?.concat([
                      { text: "Todas", value: null },
                    ])}
                    index={bedroomIndex}
                    onChange={(event: { value: string }) => {
                      if (!event.value) {
                        dispatch(setStrattoBedrooms(null));
                        dispatch(setStrattoBathrooms(null));
                        return;
                      }
                      dispatch(
                        setStrattoBedrooms(Number(event.value.split("-")[0]))
                      );
                      dispatch(
                        setStrattoBathrooms(Number(event.value.split("-")[1]))
                      );
                    }}
                    shouldClear={shouldClear}
                    resetShouldClear={() => {
                      setShouldClear(false);
                    }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />

                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />
                  <FilterStrattoAvailability
                    key={"floorplan"}
                    text="Planta tipo"
                    options={(
                      floorplanTypesOptions as unknown as FilterOptions[]
                    )?.concat([{ text: "Todas", value: null }])}
                    index={floorplanIndex}
                    onChange={(event: { value: string }) =>
                      dispatch(setStrattoFloorPlanType(event.value))
                    }
                    shouldClear={shouldClear}
                    resetShouldClear={() => {
                      setShouldClear(false);
                    }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />
                  {/* {!pathname.includes("floorplans") && <>
                  <FilterStrattoAvailability
                    key={"status"}
                    text="Disponibilidad"
                    options={status}
                    index={statusIndex}
                    onChange={(event: { value: number }) =>
                      dispatch(setStrattoActiveStatus(event.value))
                    }
                    shouldClear={shouldClear}
                    resetShouldClear={() => {
                      setShouldClear(false);
                    }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />
                  </>} */}
                  {/* <FilterStrattoAvailability
                    key={"price"}
                    text="Price"
                    options={floorplanTypesOptions}
                    index={floorplanIndex}
                    onChange={(event: { value: string }) =>
                      dispatch(setFloorPlanType(event.value))
                    }
                    shouldClear={shouldClear}
                    resetShouldClear={() => {
                      setShouldClear(false);
                    }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  />
                  <FilterStrattoAvailability
                    key={"area"}
                    text="Area"
                    options={floorplanTypesOptions}
                    index={floorplanIndex}
                    onChange={(event: { value: string }) =>
                      dispatch(setStrattoAreas(event.value))
                    }
                    shouldClear={shouldClear}
                    resetShouldClear={() => {
                      setShouldClear(false);
                    }}
                  />
                  <Divider
                    orientation="horizontal"
                    sx={{ backgroundColor: "#ffffff", height: 1 }}
                  /> */}
                </div>
              )}
            </>
          }
        <Stack direction="row">
          <BottomFilters isStratto={true} />
        </Stack>
        </Grid>
      )}
    </>
  );
};
