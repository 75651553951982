import React, { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

export function PlaceInfo() {
  const places = [
    {
      info: "Riga",
      location: { lat: 19.3847837, lng: -99.2494799 },
    },
  ];

  const [selected, setSelected] = useState<any>(null);

  return (
    <>
      {places.map((marker) => (
        <Marker
          key={`${marker.location.lat * marker.location.lng}`}
          position={{
            lat: marker.location.lat,
            lng: marker.location.lng,
          }}
          onMouseOver={() => {
            setSelected(marker);
            // マウスオーバーで<InfoWindow>が描画されます。
          }}
          // icon={{
          //   url: "url of icon",
          //   origin: new window.google.maps.Point(0, 0),
          //   anchor: new window.google.maps.Point(15, 15),
          //   scaledSize: new window.google.maps.Size(30, 30)
          //   // ここでアイコン表示の設定ができます。
          // }}
        >
          <InfoWindow position={{lat: 19.3847837, lng: -99.2494799}}> 
            <div>Riga</div>
          </InfoWindow>
        </Marker>
      ))}

      {/* {selected ? (
        // MarkerにマウスオーバーされたときにInfoWindowが表示されます。
        <InfoWindow
          position={{
            lat: selected.location.lat,
            lng: selected.location.lng
          }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <div>{selected.info}</div>
        </InfoWindow>
      ) : null} */}
    </>
  );
}
