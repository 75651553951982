import { availabilityStrattoSlice } from "./todoStratto-slice";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { availabilityStrattoService } from "../service/availabilityService";
import { availabilitySlice } from "./todo-slice";
import { availabilityActions } from "./todo-actions";

export const availabilityStrattoActions = availabilityStrattoSlice.actions;
export const availanilityAction = availabilitySlice.actions;

export const fetchAvailabilityStratto =
  (projectId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    // if (getState().availability.svgs.length === 0) {
    const response = await availabilityStrattoService.getProject(projectId);
    const floorFilterOptions: { text: any; value: any }[] = [];
    response?.views[2]?.components[0]?.apartments?.forEach((item: any) => {
      floorFilterOptions.push({
        text: item.floor_plan_name,
        value: item.floor_plan_name,
      });
    });

    dispatch(availabilityStrattoActions.setAvailabilityStratto(response));
    dispatch(availabilityActions.setFloor(floorFilterOptions as []));
    // }
  };

export const setCurrentVideoStratto =
  (
    currentVideo: string | null,
    currentVideoType: "forward" | "rewind"
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(
      availabilityStrattoActions.setCurrentVideoStratto({
        currentVideo,
        currentVideoType,
      })
    );
  };

export const setCurrentLocationViewStratto =
  (currentView: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(
      availabilityStrattoActions.setCurrentLocationViewStratto({ currentView })
    );
  };

export const setCurrentLocationsStratto =
  (currentLocation: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(
      availabilityStrattoActions.setCurrentLocationsStratto({ currentLocation })
    );
  };

export const setCurrentStrattoAvailabilityModal =
  (currentToggle: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(
      availabilityStrattoActions.setCurrentAvailabilityModalToggle({
        currentToggle,
      })
    );
  };

export const setStrattoLevel =
  (level: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoLevel({ level }));
  };

export const setStrattoSvgType =
  (currentSvgType: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoSvgType({ currentSvgType }));
  };

export const fetchAvailabilityStrattoUnits =
  (projectId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const response = await availabilityStrattoService.getFloorplans(projectId);
    dispatch(
      availabilityStrattoActions.setStrattoUnits({ data: response?.floorPlans })
    );
  };
export const setStrattoCurrentApartment =
  (apartment: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setSelectedApartment({ apartment }));
  };

  export const setStrattoBedrooms =
  (bedrooms: number | null): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoBedrooms({ bedrooms }));
  };

export const setStrattoBathrooms =
  (bathrooms: number | null): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoBathrooms({ bathrooms }));
  };

export const setStrattoPrices =
  (prices: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoPrices({ prices }));
  };

export const setStrattoAreas =
  (areas: string | number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoAreas({ areas }));
  };

  export const setStrattoFloorPlanType =
  (floorplanType: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setStrattoFloorPlanType({ floorplanType }));
  };

  export const setStrattoActiveStatus =
  (status: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(availabilityStrattoActions.setCurrentStatusFilter({ status }));
  };

  export const cleanStrattoFilters =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(availabilityStrattoActions.cleanStrattoFilters());
  };
