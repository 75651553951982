import {
  ButtonStratto,
  HomeIcon,
} from "../ComponentsUtilities";
import { useStyles } from "./BRollScreenStyle";
import { useViewsStratto } from "../../hooks/hooksStratto";

export default function BRollScreen() {
  const classes = useStyles();
  const views = useViewsStratto();
  return (
    <div className={classes.containerBroll}>
      <video controls autoPlay className={classes.videoScreen}>
        <source
          src={"https://athum.com/360/768/videos/PropertyBRoll-Web.mp4"}
          type="video/mp4"
        />
      </video>
      <HomeIcon />
      <div className={classes.buttonBroll}>
        {views[1]?.components[0]?.buttons.map((e: any, i: any) => {
          return (
            <ButtonStratto
              bg={e?.backgroundColor}
              marginRight="33px"
              key={i}
              name={`${e.text}`}
              path={`${e.goTo === "view-3" ? "floorplans" : "amenities-broll"}`}
            />
          );
        })}
        {/* <ButtonStratto marginRight="33px" name="Depas" path="floorplans" />
          <ButtonStratto name="Amenidades" path="amenities-broll" /> */}
      </div>
      
    </div>
  );
}
