import { useAppDispatch, useCurrentLoading } from "../../hooks";
import { ReactSVG } from "react-svg";
import { useCallback, useMemo, useRef, useState } from "react";
import { FloorplanstrattoCard } from "../FloorplanCard";
import { Box, CircularProgress } from "@mui/material";
import { setCurrentStrattoAvailabilityModal } from "../../store/todoStratto-actions";
import {
  useStrattoFiltersValues,
  useStrattoUnits,
} from "../../hooks/hooksStratto";

export const fills: { [key: string]: string } = {
  available: "#B4FFEE",
  reserved: "#FFE7B6",
  taken: "#C0C7FF",
  sold: "#FFC0C0",
  nonavailable: "#C4C4C4",
  availableSelect: "2eeec1E",
};

interface Props {
  svg: string;
  scaleZoom: string | undefined;
}

export const InteractiveFloorStratto = ({ svg, scaleZoom }: Props) => {
  const currentSvg = useRef(null);
  const hoveredElementRef = useRef<any>(null);
  const selectedElementRef = useRef<any>(null);
  const lockElementRef = useRef<any>(null);
  const lockUnitRef = useRef<any>(null);
  const [mousePosition, setMousePosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const strattoUnits = useStrattoUnits()

  const [
    bedroomFilter,
    bathroomFilter,
    floorplanFilter,
    priceFilter,
    areaFilter,
    levelFilter,
    statusFilter
  ] = useStrattoFiltersValues();

  const filterObject = useMemo(()=>{
    return {
      bedrooms: bedroomFilter,
      bathrooms: bathroomFilter,
      'floor_plan_code': floorplanFilter?.toUpperCase() || null,
      status: statusFilter,
      floor:levelFilter
    }
  },[bathroomFilter, bedroomFilter, floorplanFilter, levelFilter, statusFilter])


  const dispatch = useAppDispatch();

  const loading = useCurrentLoading();

  const renderSVG = useCallback(() => {
    const svgEmbed: any = document.getElementsByClassName("injected-svg")[0];
    if (svgEmbed) {
      svgEmbed.setAttribute("preserveAspectRatio", "xMidYMid slice");
    }
    const levelElements = document.querySelectorAll("g");
    levelElements?.forEach((el) => {
      el.classList.remove("floor");
      el.setAttribute("style", `fill:transparent; opacity:1`);
    });

    document
      .querySelectorAll(".st2")
      .forEach((item: any) => item.setAttribute("style", `opacity: 0`));
    // document
    //   .querySelectorAll(".mk")
    //   .forEach((item: any) => item.setAttribute("style", `opacity: 0 !important`));

    // For the SVG after video is finished

    // Filtering units

    

    const filteredUnits =
      bathroomFilter == null &&
      bedroomFilter == null &&
      floorplanFilter == null &&
      priceFilter == null &&
      areaFilter == null &&
      levelFilter == null
        ? strattoUnits
        : strattoUnits.filter((unit: any) => {
            return Object.entries(filterObject).every(([key, value]) => {
              if (value === null || value === undefined) return true;
              return unit[key] === value;
            });
          });

    const filteredOutUnits =
      bathroomFilter !== null ||
      bedroomFilter !== null ||
      floorplanFilter !== null ||
      priceFilter !== null ||
      areaFilter !== null ||
      levelFilter !== null ?
         strattoUnits.filter((unit: any) => {
            return Object.entries(filterObject).some(([key, value]) => {
              return value!==null && value!==undefined && unit[key] !== value;
            });
            // unit.bathrooms === bathroomFilter &&
            //   unit.bedrooms === bedroomFilter &&
            //   unit.floor_plan_code?.toLowerCase() === floorplanFilter &&
            //   unit.floor === levelFilter;
          }) : [];



    filteredUnits?.forEach((el: any) => {
      const { status , name } = el;

      const floorplan = document.getElementById(`U-${name}`);
      const floorplanPolygon = document.getElementById(`P-${name}`);
      const floorplanCircleNumber = document.getElementById(`M-${name}`);

      if (floorplanPolygon) {
        floorplanPolygon?.classList.remove("st2");
        floorplanPolygon.setAttribute("style", `fill:transparent; opacity: 1`);
        if (status === 0) {
          floorplanPolygon?.classList.remove("st2");
          floorplanPolygon.setAttribute(
            "style",
            `stroke: #3db3d3; stroke-width: 2px`
          );
        }

        if (status === 1) {
          floorplanPolygon?.classList.remove("st2");
          floorplanPolygon.setAttribute(
            "style",
            `fill: black; opacity: 0.75; stroke: #000; stroke-width: 2px`
          );
        }

        if (status !== statusFilter && statusFilter !== null) {
          floorplanPolygon?.classList.remove("st2");
          floorplanPolygon.setAttribute(
            "style",
            `fill: #848484; opacity: 0.75`
          );
        }

        if (lockUnitRef.current === name) {
          floorplanPolygon.setAttribute(
            "style",
            `fill:${fills['available']}; opacity: 0.5`
          );
        }
      }

      if (floorplanCircleNumber) {
        floorplanCircleNumber.setAttribute(
          "style",
          `fill:${fills[status]}; opacity: 1;`
        );
      }

      if (floorplan && status === 0) {
        floorplan.addEventListener("mousedown", (e: any) => {
          lockUnitRef.current = name;
          const { clientX: x, clientY: y } = e;
          setMousePosition({ x, y });
          lockElementRef.current = true;
          hoveredElementRef.current = el;
          selectedElementRef.current = el;
        });
        floorplan.addEventListener(
          "mouseenter",
          (e: any) => {
            if (!lockElementRef.current) {
              const { clientX: x, clientY: y } = e;
              setMousePosition({ x, y });
              if (floorplanPolygon) {
                floorplanPolygon?.classList.remove("st2");
                floorplanPolygon.setAttribute(
                  "style",
                  `fill:${fills['available']}; opacity: 0.5`
                );
              }
              hoveredElementRef.current = el;
            }
          },
          false
        );
      }
    });

    filteredOutUnits?.forEach((el: any) => {
      const { status , name } = el;

      const floorplanPolygon = document.getElementById(`P-${name}`);

      if (floorplanPolygon) {
        floorplanPolygon?.classList.remove("st2");
        floorplanPolygon.setAttribute("style", `fill:transparent; opacity: 1`);
        if (status === 0) {
          floorplanPolygon?.classList.remove("st2");
          floorplanPolygon.setAttribute(
            "style",
            `stroke: #3db3d3; stroke-width: 2px; fill: black; opacity: 0.5`
          );
        }

        if (status === 1) {
          floorplanPolygon?.classList.remove("st2");
          floorplanPolygon.setAttribute(
            "style",
            `fill: black; opacity: 0.75; stroke: #000; stroke-width: 2px`
          );
        }
      }
    });
  }, [areaFilter, bathroomFilter, bedroomFilter, filterObject, floorplanFilter, levelFilter, priceFilter, statusFilter, strattoUnits]);

  if (!svg) return null;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        overflow: "auto",
      }}
      onMouseEnter={() => {
        if (!lockElementRef.current) {
          setMousePosition(null);
          hoveredElementRef.current = null;
        }
      }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}>
          <CircularProgress sx={{ color: "#3948FF" }} />
        </Box>
      ) : (
        <ReactSVG
          ref={currentSvg}
          afterInjection={(err, svg) => {
            if (svg) renderSVG();
          }}
          src={`${svg}?type=svg`}
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            scale: scaleZoom,
          }}
          className="svg-container"
        />
      )}

      {mousePosition?.x && mousePosition?.y && hoveredElementRef.current && (
        <FloorplanstrattoCard
          x={mousePosition?.x}
          y={mousePosition?.y}
          selectedFloorplan={hoveredElementRef.current}
          clearSelectedFloor={() => {
            hoveredElementRef.current = null;
            selectedElementRef.current = null;
            lockElementRef.current = false;
            lockUnitRef.current = null;
            setMousePosition(null);
          }}
          onClick={() => {
            hoveredElementRef.current = null;
            lockElementRef.current = false;
            lockUnitRef.current = null;
            setMousePosition(null);
            dispatch(setCurrentStrattoAvailabilityModal(true));
          }}
        />
      )}
    </div>
  );
};
