import { Box, Typography, useMediaQuery } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ReactComponent as Alberca } from "../../assets/icons/Alberca.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useState } from "react";
import { ReactComponent as VideoTour } from "../../assets/icons/VideoTour.svg";
import { useStyles } from "../AvailabilityScreen/AvailabilityModal";
import { useNavigate } from "react-router-dom";

interface AmenitiesAreaModalProps {
  onClose: any;
  open?: any;
  amentityData?: any;
}

const InfoMobileView = ({ amentityData }: any) => {
  const mobileMini = useMediaQuery("(max-width:320px)");
  const mobile = useMediaQuery("(max-width:601px)");
  const tabletMini = useMediaQuery("(max-width:820px)");
  const setWidthText = () => {
    if (mobileMini) {
      return "235px";
    }
    if (mobile) {
      return "270px";
    }
    return "390px";
  };
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"#FFFFFF"}
      paddingBottom={"70px"}
      overflow={"auto"}>
      <Box
        width={"100%"}
        textAlign={"left"}
        padding={tabletMini ? "0 80px" : "0 40px"}
        marginTop={tabletMini ? '140px' : '0px'}
        >
        <div>
          <Typography
            fontWeight={300}
            fontSize={"16px"}
            color={"rgba(0, 0, 0, 1)"}>
            AMENIDADES
          </Typography>
        </div>
        <Box marginTop={"20px"}>
          <img width={"40px"} height={"40px"} src={amentityData?.iconImage} alt="icon"/>
        </Box>
        <Box marginTop={"20px"}>
          <Typography
            fontWeight={900}
            fontSize={"28px"}
            color={"rgba(0, 0, 0, 1)"}>
            {amentityData?.name}
          </Typography>
        </Box>
        <Box
          marginTop={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}>
          <Typography
            fontSize={"20px"}
            fontWeight={300}
            color={"rgba(0, 0, 0, 1)"}>
            Nivel:
          </Typography>
          <Typography
            marginLeft={"6px"}
            fontSize={"20px"}
            fontWeight={700}
            color={"rgba(0, 0, 0, 1)"}>
            {amentityData?.level}
          </Typography>
        </Box>
        <Box marginTop={"20px"} width={setWidthText()}>
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            color={"rgba(0, 0, 0, 1)"}>
            {amentityData?.subText}
          </Typography>
        </Box>
        <Box marginTop={"20px"}>
          <ul
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "rgba(0, 0, 0, 1)",
            }}>
            {amentityData?.featureText?.map((text: any, index: number) => (
              <li key={index} style={{ marginTop: "20px" }}>
                {text.feature}
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default function AmenitiesAreaModal({
  onClose,
  open,
  amentityData,
}: AmenitiesAreaModalProps) {
  const styles = useStyles();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const desktopSmall = useMediaQuery("(max-width:1500px)");
  const desktopNormal = useMediaQuery("(max-width:1870px)");
  const tablet = useMediaQuery("(max-width:1180px)");
  const tabletSmall = useMediaQuery("(max-width:1024px)");
  const tabletMini = useMediaQuery("(max-width:820px)");
  const mobile = useMediaQuery("(max-width:601px)");
  const mobileMini = useMediaQuery("(max-width:320px)");
  const setWidthImg = () => {
    if (tabletMini) {
      return "100%";
    }
    if (tabletSmall) {
      return "400px";
    }
    if (tablet) {
      return "565px";
    }
    if (desktopSmall) {
      return "800px";
    }
    if (desktopNormal) {
      // return '785px'
      return "1000px";
    }
    return "1176px";
  };
  const setHeightImg = () => {
    if (tabletMini) {
      return "100%";
    }
    if (tablet) {
      return "535px";
    }
    if (desktopSmall) {
      return "535px";
    }
    if (desktopNormal) {
      return "535px";
    }
    return "600px";
  };
  const setButtonWidth = () => {
    if (mobile) {
      return "100%";
    }
    if (tabletMini) {
      return "177px";
    }
    if (tablet) {
      return "150px";
    }
    return "220px";
  };
  const setButtonHeight = () => {
    if (tabletMini) {
      return "46px";
    }
  };
  const fontSizeButton = () => {
    if (tablet) {
      return "15px";
    }
    return "20px";
  };
  const slidesPerView = () => {
    if (tabletSmall) {
      return 3;
    }
    if (tablet) {
      return 4;
    }
    if (desktopSmall) {
      return 6;
    }
    if (desktopNormal) {
      return 8;
    }
    return 8;
  };
  const closeButtonRight = () => {
    if (tabletMini) {
      return "1%";
    }
    if (desktopNormal) {
      return "-63px";
    }
    return "-70px";
  };
  const marginSpace = () => {
    if (tabletMini) {
      return "0px";
    }
    if (desktopNormal) {
      return "50px";
    }
    return "55px";
  };
  const setFontSizeIcon = () => {
    if (mobile) {
      return "19px";
    }
    if (tabletMini) {
      return "40px";
    }
    return;
  };
  const setUtilitiesStrattoRight = () => {
    if (mobileMini) {
      return "unset";
    }
    if (tabletMini) {
      return "-2px";
    }
    return "-79px";
  };

  const setUtilitiesStrattoBottom = () => {
    if (mobileMini) {
      return "311px";
    }
    if (tabletMini) {
      return "58px";
    }
    return "32px";
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <div>
          <Box width={"80%"} height={"80%"}>
            <Box
              bgcolor={tabletMini ? "" : "rgba(255, 255, 255, 1)"}
              position={"absolute"}
              top={tabletMini ? "unset" : "50%"}
              left={tabletMini ? "unset" : "49%"}
              sx={{
                transform: tabletMini ? "unset" : "translate(-50%, -50%)",
                outline: "none",
              }}
              borderRadius={"15px"}
              padding={tabletMini ? "0px" : "25px"}
              width={tabletMini ? "100%" : "unset"}
              height={tabletMini ? "100%" : "unset"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={tabletMini ? "column":"unset"}
                justifyContent={tabletMini ? "center" : "unset"}
                height={tabletMini ? "100%" : "unset"}>
                <Box
                  width={tabletMini ? "100%" : "unset"}
                  height={tabletMini ? "50%" : "unset"}>
                  <Box
                    width={setWidthImg()}
                    height={setHeightImg()}
                    borderRadius={tabletMini ? "" : "15px"}
                    overflow={"hidden"}>
                    <Swiper
                      spaceBetween={10}
                      navigation={true}
                      // error classList with MUI Modal
                      // thumbs= {{swiper: thumbsSwiper}}
                      thumbs={{
                        swiper:
                          thumbsSwiper && !thumbsSwiper.destroyed
                            ? thumbsSwiper
                            : null,
                      }}
                      modules={[FreeMode, Navigation, Thumbs]}>
                      <SwiperSlide>
                        <video
                          controls
                          className={styles.videoScreen}
                          autoPlay
                          muted>
                          <source src={amentityData?.video} type="video/mp4" />
                        </video>
                      </SwiperSlide>
                      {amentityData?.imageGallery?.length &&
                        amentityData?.imageGallery?.map((image: any) => (
                          <SwiperSlide>
                            <img
                              width={"100%"}
                              height={"100%"}
                              // height={tabletMini? '50%' :'100%'}
                              style={{ objectFit: "cover" }}
                              src={image?.imageUri}
                              alt="nature"
                            />
                          </SwiperSlide>
                        ))}
                      
                    </Swiper>
                    
                  </Box>
                </Box>
                {tabletMini && (
                        // <SwiperSlide>
                          <InfoMobileView amentityData={amentityData}/>
                        // </SwiperSlide>
                      )}
                {!tabletMini && (
                  <Box marginLeft={marginSpace()}>
                    <div>
                      <Typography
                        fontWeight={300}
                        fontSize={"16px"}
                        color={"rgba(0, 0, 0, 1)"}>
                        AMENIDADES
                      </Typography>
                    </div>
                    <Box marginTop={"20px"}>
                      <img src={amentityData?.iconImage} alt="icon" />
                    </Box>
                    <Box marginTop={"20px"}>
                      <Typography
                        fontWeight={900}
                        fontSize={"28px"}
                        color={"rgba(0, 0, 0, 1)"}>
                        {amentityData?.name}
                      </Typography>
                    </Box>
                    <Box
                      marginTop={"15px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}>
                      <Typography
                        fontSize={"20px"}
                        fontWeight={300}
                        color={"rgba(0, 0, 0, 1)"}>
                        Nivel:
                      </Typography>
                      <Typography
                        marginLeft={"6px"}
                        fontSize={"20px"}
                        fontWeight={700}
                        color={"rgba(0, 0, 0, 1)"}>
                        {amentityData?.level}
                      </Typography>
                    </Box>
                    <Box marginTop={"20px"} width={"390px"}>
                      <Typography
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"rgba(0, 0, 0, 1)"}>
                        {amentityData?.subText}
                      </Typography>
                    </Box>
                    <Box marginTop={"20px"}>
                      <ul
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "rgba(0, 0, 0, 1)",
                        }}>
                        {amentityData?.featureText?.map((item: any) => (
                          <li style={{ marginTop: "20px" }}>{item?.feature}</li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={tabletMini ? "center" : "unset"}
                marginTop={"19px"}>
                {!tabletMini && amentityData?.imageGallery?.length > 0 && (
                  <Box
                    width={setWidthImg()}
                    height={"89px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={10}
                      slidesPerView={slidesPerView()}
                      freeMode={true}
                      watchSlidesProgress={true}
                      navigation={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      style={{ width: "100%", height: "100%" }}>
                      <SwiperSlide>
                        <div className="swiperWrapper">
                          <img
                            width={"100%"}
                            height={"100%"}
                            src={amentityData?.imageGallery?.[0].imageUri}
                            alt="nature"
                          />
                          <div className="overlayImage">
                            <VideoTour />
                          </div>
                        </div>
                      </SwiperSlide>

                      {amentityData?.imageGallery?.map((item: any) => {
                        return (
                          <SwiperSlide>
                            <div className="swiperWrapper">
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={item.imageUri}
                                alt="nature"
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </Box>
                )}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  marginLeft={marginSpace()}
                  position={tabletMini ? "absolute" : "unset"}
                  zIndex={tabletMini ? "999" : "0"}
                  bottom={tabletMini ? "0px" : ""}
                  justifyContent={tabletMini ? "center" : "unset"}
                  width={tabletMini ? "100%" : "unset"}
                  bgcolor={"white"}
                  paddingBottom={tabletMini ? '15px':'0'}
                  >
                  <button
                    onClick={() => navigate("/floorplans")}
                    style={{
                      backgroundColor: "rgba(86, 176, 192, 1)",
                      padding: "20px 0",
                      fontSize: fontSizeButton(),
                      fontWeight: "700",
                      borderRadius: "15px",
                      color: "rgba(255, 255, 255, 1)",
                      cursor: "pointer",
                      height: setButtonHeight(),
                      width: setButtonWidth(),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px solid rgba(86, 176, 192, 1)",
                      marginLeft: mobile ? "10px" : "0px",
                    }}>
                    Depas
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://rigabosques.com/contact.html",
                        "_blank"
                      )
                    }
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      padding: "20px 0",
                      fontSize: fontSizeButton(),
                      fontWeight: "700",
                      borderRadius: "15px",
                      cursor: "pointer",
                      marginLeft: "15px",
                      height: setButtonHeight(),
                      width: setButtonWidth(),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px solid rgba(86, 176, 192, 1)",
                      color: "rgba(86, 176, 192, 1)",
                      marginRight: mobile ? "10px" : "0px",
                    }}>
                    Agenda tu visita
                  </button>
                </Box>
              </Box>
              <div onClick={onClose}>
                <Box
                  position={"absolute"}
                  width={"48px"}
                  height={"48px"}
                  bgcolor={tabletMini ? "" : "rgba(255, 255, 255, 1)"}
                  top={tabletMini ? "1%" : "0"}
                  right={closeButtonRight()}
                  sx={{ cursor: "pointer" }}
                  borderRadius={"50%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  zIndex={100}>
                  <CloseIcon sx={{ fontSize: setFontSizeIcon() }} />
                </Box>
              </div>
              {/* <Box position={'absolute'} right={setUtilitiesStrattoRight()} bottom={setUtilitiesStrattoBottom()} left={mobileMini? '64px':'unset'} zIndex={999}>
                                <UtilitiesStratto/>
                            </Box> */}
            </Box>
          </Box>
        </div>
      </Modal>

      {/* <Modal */}
    </>
  );
}
